<template>
  <v-form>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.fileImage"
            :error-messages="fileImageErrors"
            :label="$t('fileImage')"
            @input="$v.item.fileImage.$touch()"
            @blur="$v.item.fileImage.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.slug"
            :error-messages="slugErrors"
            :label="$t('slug')"
            @input="$v.item.slug.$touch()"
            @blur="$v.item.slug.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.texte"
            :error-messages="texteErrors"
            :label="$t('texte')"
            required
            @input="$v.item.texte.$touch()"
            @blur="$v.item.texte.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.indice"
            :error-messages="indiceErrors"
            :label="$t('indice')"
            required
            @input="$v.item.indice.$touch()"
            @blur="$v.item.indice.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.intro"
            :error-messages="introErrors"
            :label="$t('intro')"
            required
            @input="$v.item.intro.$touch()"
            @blur="$v.item.intro.$touch()"
          />
        </v-col>
      
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.image"
            :error-messages="imageErrors"
            :label="$t('image')"
            @input="$v.item.image.$touch()"
            @blur="$v.item.image.$touch()"
          />
        </v-col>
      </v-row>
      
      <v-row>
        <v-col cols="12" sm="6" md="6">
          <v-text-field
            v-model="item.titre"
            :error-messages="titreErrors"
            :label="$t('titre')"
            required
            @input="$v.item.titre.$touch()"
            @blur="$v.item.titre.$touch()"
          />
        </v-col>
      
        <v-col cols="12"></v-col>
      </v-row>
      
    </v-container>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import { mapActions } from 'vuex';
import { mapFields } from 'vuex-map-fields';

export default {
  name: 'EnigmeForm',
  mixins: [validationMixin],
  props: {
    values: {
      type: Object,
      required: true
    },

    errors: {
      type: Object,
      default: () => {}
    },

    initialValues: {
      type: Object,
      default: () => {}
    }
  },
  mounted() {
  },
  data() {
    return {
        fileImage: null,
        slug: null,
        texte: null,
        indice: null,
        intro: null,
        image: null,
        titre: null,
    };
  },
  computed: {

    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },

    fileImageErrors() {
      const errors = [];

      if (!this.$v.item.fileImage.$dirty) return errors;

      has(this.violations, 'fileImage') && errors.push(this.violations.fileImage);


      return errors;
    },
    slugErrors() {
      const errors = [];

      if (!this.$v.item.slug.$dirty) return errors;

      has(this.violations, 'slug') && errors.push(this.violations.slug);


      return errors;
    },
    texteErrors() {
      const errors = [];

      if (!this.$v.item.texte.$dirty) return errors;

      has(this.violations, 'texte') && errors.push(this.violations.texte);

      !this.$v.item.texte.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    indiceErrors() {
      const errors = [];

      if (!this.$v.item.indice.$dirty) return errors;

      has(this.violations, 'indice') && errors.push(this.violations.indice);

      !this.$v.item.indice.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    introErrors() {
      const errors = [];

      if (!this.$v.item.intro.$dirty) return errors;

      has(this.violations, 'intro') && errors.push(this.violations.intro);

      !this.$v.item.intro.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    imageErrors() {
      const errors = [];

      if (!this.$v.item.image.$dirty) return errors;

      has(this.violations, 'image') && errors.push(this.violations.image);


      return errors;
    },
    titreErrors() {
      const errors = [];

      if (!this.$v.item.titre.$dirty) return errors;

      has(this.violations, 'titre') && errors.push(this.violations.titre);

      !this.$v.item.titre.required && errors.push(this.$t('Field is required'));

      return errors;
    },

    violations() {
      return this.errors || {};
    }
  },
  methods: {
  },
  validations: {
    item: {
      fileImage: {
      },
      slug: {
      },
      texte: {
        required,
      },
      indice: {
        required,
      },
      intro: {
        required,
      },
      image: {
      },
      titre: {
        required,
      },
    }
  }
};
</script>
